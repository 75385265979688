import PropTypes from "prop-types"
import React from "react"
import { Collapse } from "@blueprintjs/core"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from  '@fortawesome/pro-solid-svg-icons'

var classNames = require('classnames');

class CollapseRow extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isOpen: this.props.isOpen ? true : false,
      }
      this.handleClick = this.handleClick.bind(this)
    }
  
    handleClick() {
      this.setState({ isOpen: !this.state.isOpen })
    }
  
    render() {
      return (
        <div className="collapse-container">
          <div className="columns collapse-heading-icon is-mobile">
            <div className="column is-hidden-mobile"></div>
            <div className="column is-20-mobile is-12-tablet is-12-desktop is-12-widescreen is-12-fullhd" onClick={this.handleClick}>{this.props.heading}</div>
            <div className="column is-1 collapse-icon">
              {!this.state.isOpen && (
                <FontAwesomeIcon icon={faPlus} onClick={this.handleClick} />
              )}
              {this.state.isOpen && (
                <FontAwesomeIcon icon={faMinus} onClick={this.handleClick} />
              )}
            </div>
            <div className="column is-2-mobile is-5-tablet is-5-desktop is-5-widescreen is-5-fullhd"></div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="columns collapse-row">
              <div className="column is-2-mobile is-6-tablet is-6-desktop is-6-widescreen is-6-fullhd"></div>
              <div className="column" style={{ marginTop: "1rem" }}>{this.props.paragraphs}</div>
              <div className="column is-2-mobile is-6-tablet is-6-desktop is-6-widescreen is-6-fullhd"></div>
            </div>
          </Collapse>
        </div>
      )
    }
  }

CollapseRow.propTypes = {
    isOpen: PropTypes.bool,
    heading: PropTypes.element,
    paragraphs: PropTypes.element
}


export default CollapseRow